const numberDate = true;

export default [
  {
    title: 'ТИП ПРОТОКОЛА',
    params: [
      {
        title: 'ТИП ПРОТОКОЛА',
        key: 'protocol_type'
      },
      {
        title: 'ПРОТОКОЛ,НА ОСНОВАНИИ КОТОРОГО ПРОИСХОДИТ СОЗДАНИЕ',
        key: 'parent_offence_id'
      }
    ]
  },
  {
    title: 'УСТАНОВЛЕНИЕ СОБСТВЕННИКА',
    params: [
      {
        title: 'ЗАПРОС НА УСТАНОВЛЕНИЕ СОБСТВЕННИКА',
        numberDate,
        key:
          'request_establishment_owner_number request_establishment_owner_date',
        params: [
          {
            title: 'РЕЗУЛЬТАТ',
            key: 'request_establishment_owner_result'
          },
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_request_establishment_owner',
            files: ''
          }
        ]
      }
    ]
  },
  {
    title: 'СОСТАВЛЕНИЕ УВЕДОМЛЕНИЯ',
    params: [
      {
        title: 'УВЕДОМЛЕНИЕ О ДОПУЩЕННОМ ПРАВОНАРУШЕНИИ ',
        numberDate,
        key:
          'notice_offence_place_owner_number notice_offence_place_owner_date',
        params: [
          {
            nsi_dictionary_id: 13,
            title: 'СТАТЬЯ ПРАВОНАРУШЕНИЙ',
            key: 'article_offence_place_owner'
          },
          {
            nsi_dictionary_id: 14,
            title: 'ОСНОВАНИЕ ДЛЯ СОСТАВЛЕНИЯ ПРОТОКОЛА',
            key: 'base_protocol_place_owner'
          },
          {
            title: 'ДАТА НАПРАВЛЕНИЯ УВЕДОМЛЕНИЯ О ДОПУЩЕННОМ ПРАВОНАРУШЕНИИ',
            key: 'date_notification_offence'
          }
        ]
      }
    ]
  },
  {
    title: 'СОСТАВЛЕНИЕ ПРОТОКОЛА',
    params: [
      {
        title: 'ПРОТОКОЛ ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ  ',
        numberDate,
        key:
          'offence_protocol_place_owner_number offence_protocol_place_owner_date',
        params: [
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_offences_protocol_place_owner',
            files: ''
          }
        ]
      },
      {
        title: 'АКТ К ПРОТОКОЛУ ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ  ',
        numberDate,
        key:
          'administrative_offense_protocol_act_number administrative_offense_protocol_act_date'
      },
      {
        nsi_dictionary_id: 26,
        title:
          'СВЕДЕНИЯ О ЛИЦЕ, В ОТНОШЕНИИ КОТОРОГО ВОЗБУЖДЕНО ДЕЛО ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ',
        key: 'place_owner_person_info'
      },
      {
        nsi_dictionary_id: 28,
        title:
          'СТАТУС ЛИЦА, В ОТНОШЕНИИ КОТОРОГО ВОЗБУЖДЕНО ДЕЛО ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ',
        key: 'place_owner_person_status'
      }
    ]
  },
  {
    title: 'ПРИВЛЕЧЕНИЕ К АДМИНИСТРАТИВНОЙ ОТВЕТСТВЕННОСТИ',
    params: [
      {
        numberDate,
        title: 'ПОСТАНОВЛЕНИЕ О НАЗНАЧЕНИИ АДМИНИСТРАТИВНОГО НАКАЗАНИЯ  ',
        key: 'decree_punish_place_owner_number decree_punish_place_owner_date',
        params: [
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_decree_punish_place_owner',
            files: ''
          }
        ]
      },
      {
        title: 'РЕШЕНИЕ КОМИССИИ ОТ ',
        key: 'commission_decision_place_owner_date',
        params: [
          {
            title: 'РЕШЕНИЕ',
            nsi_dictionary_id: 24,
            key: 'commission_decision_place_owner'
          },
          {
            title: 'Дата окончания срока добровольной оплаты',
            key: 'voluntary_payment_end_date_place_owner',
            if: true,
            func: protocol => {
              return {
                value: protocol.commission_decision_place_owner === 205,
                template: 'base'
              };
            }
          },
          {
            title: 'Сумма штрафа',
            key: 'amount_of_penalty_place_owner',
            if: true,
            func: protocol => {
              return {
                value: protocol.commission_decision_place_owner === 205,
                template: 'base'
              };
            }
          },
          {
            title: 'Причина отказа',
            key: 'commission_decision_place_owner_reject',
            if: true,
            func: protocol => {
              return {
                value: protocol.commission_decision_place_owner === 86,
                template: 'base'
              };
            }
          },
          {
            title: 'ОПИСАНИЕ',
            key: 'commission_decision_place_owner_descr'
          },
          {
            title: 'ДОКУМЕНТЫ',
            key:
              'file_nto_private_place_commission_decision_object_place_descr',
            files: ''
          }
        ]
      },
      {
        title: 'НАЛИЧИЕ ХОДАТАЙСТВА: ',
        key: 'has_petition',
        params: [
          {
            title: 'ХОДАТАЙСТВО № ',
            numberDate,
            key: 'petition_number petition_date'
          },
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_petition',
            files: ''
          }
        ]
      },
      {
        title: 'ДАТА НАСТУПЛЕНИЯ АДМИНИСТРАТИВНОЙ ОТВЕТСТВЕННОСТИ',
        key: 'administrative_responsibility_place_owner_date_begin'
      },
      {
        title: 'ДАТА ОКОНЧАНИЯ ПЕРИОДА АДМИНИСТРАТИВНОЙ ОТВЕТСТВЕННОСТИ',
        key: 'administrative_responsibility_place_owner_date_end'
      },
      {
        title:
          'ДАТА ВРУЧЕНИЯ ПОСТАНОВЛЕНИЯ АК О ШТРАФЕ ИЛИ ДАТА ВОЗВРАТА С ПОЧТЫ',
        key: 'ak_decree_place_owner'
      }
    ]
  },
  {
    title: 'ПОДАЧА ЖАЛОБЫ',
    params: [
      {
        title: 'НАЛИЧИЕ ЖАЛОБЫ НА ПОСТАНОВЛЕНИЕ АК',
        key: 'has_ak_decree_complaint_place_owner',
        params: [
          {
            nsi_dictionary_id: 16,
            title: 'СУД, РАССМАТРИВАЮЩИЙ ДЕЛО ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ АК',
            key: 'ak_decree_complaint_court_place_owner'
          },
          {
            title: 'НОМЕР ДЕЛА ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ АК',
            key: 'ak_decree_complaint_number_place_owner'
          },
          {
            title: 'ДАТА РЕГИСТРАЦИИ ДЕЛА ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ АК',
            key: 'ak_decree_complaint_date_place_owner'
          },
          {
            nsi_dictionary_id: 17,
            title: 'РЕШЕНИЕ СУДА ПО ДЕЛУ ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ АК',
            key: 'ak_decree_complaint_decision_place_owner'
          },
          {
            title: 'ДАТА РЕШЕНИЯ СУДА ПО ДЕЛУ ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ АК',
            key: 'ak_decree_complaint_decision_date_place_owner'
          }
        ]
      }
    ]
  },
  {
    title: 'ПОДАЧА АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ',
    params: [
      {
        title: 'НАЛИЧИЕ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ НА РЕШЕНИЕ СУДА',
        key: 'has_appeal_place_owner',
        params: [
          {
            nsi_dictionary_id: 18,
            title: 'ЛИЦО, ОБЖАЛУЮЩЕЕ РЕШЕНИЕ СУДА',
            key: 'appeal_person_place_owner'
          },
          {
            nsi_dictionary_id: 27,
            title: 'СУД, РАССМАТРИВАЮЩИЙ АПЕЛЛЯЦИОННУЮ ЖАЛОБУ',
            key: 'appeal_court_place_owner'
          },
          {
            title: 'НОМЕР ДЕЛА О РАССМОТРЕНИИ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ',
            key: 'appeal_number_place_owner'
          },
          {
            title: 'ДАТА РЕГИСТРАЦИИ ДЕЛА ОБ ОБЖАЛОВАНИИ ПОСТАНОВЛЕНИЯ АК',
            key: 'appeal_date_place_owner'
          },
          {
            nsi_dictionary_id: 19,
            title:
              'РЕШЕНИЕ СУДА ПО ДЕЛУ О РАССМОТРЕНИИ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ НА РЕШЕНИЕ СУДА',
            key: 'appeal_decision_place_owner'
          },
          {
            title:
              'ДАТА РЕШЕНИЯ СУДА ПО ДЕЛУ О РАССМОТРЕНИИ АПЕЛЛЯЦИОННОЙ ЖАЛОБЫ НА РЕШЕНИЕ СУДА',
            key: 'appeal_decision_date_place_owner'
          }
        ]
      }
    ]
  },
  {
    title: 'ОПЛАТА ШТРАФА',
    params: [
      {
        title: 'ДАТА ОКОНЧАНИЯ СРОКА ДОБРОВОЛЬНОЙ ОПЛАТЫ',
        key: 'voluntary_payment_end_date_place_owner'
      },
      {
        title: 'СУММА ШТРАФА',
        key: 'amount_of_penalty_place_owner'
      },
      {
        nsi_dictionary_id: 20,
        title: 'СТАТУС ОПЛАТЫ ШТРАФА',
        key: 'penalty_payment_status_place_owner'
      },
      {
        title: 'СУММА ОПЛАЧЕННОГО ШТРАФА',
        key: 'amount_of_penalty_place_owner_paid'
      },
      {
        title: 'Дата взыскания',
        key: 'penalty_collection_date'
      }
    ]
  },
  {
    title: 'ПЕРЕДАЧА В ССП',
    params: [
      {
        numberDate,
        title:
          'ПОСТАНОВЛЕНИЕ О ВОЗБУЖДЕНИИ ДЕЛА ОБ АДМИНИСТРАТИВНОМ ПРАВОНАРУШЕНИИ  ',
        key:
          'decision_initiate_proceedings_number decision_initiate_proceedings_date',
        params: [
          {
            title: 'ДОКУМЕНТЫ',
            key: 'file_decision_initiate_proceedings_docs',
            files: ''
          }
        ]
      },
      {
        title: 'ДАТА ПЕРЕДАЧИ ПОСТАНОВЛЕНИЯ В ССП',
        key: 'ssp_enforcement_date_object_owner'
      },
      {
        nsi_dictionary_id: 21,
        title: 'СТАТУС ПЕРЕДАЧИ ПОСТАНОВЛЕНИЯ',
        key: 'ssp_enforcement_status_place_owner'
      },
      {
        nsi_dictionary_id: 22,
        title: 'НАЛИЧИЕ ПРОТОКОЛА ЗА НЕУПЛАТУ ШТРАФА',
        key: 'ssp_enforcement_protocol_place_owner'
      },
      {
        nsi_dictionary_id: 25,
        title: 'СТАТУС ПЕРИОДА ДОБРОВОЛЬНОЙ ОПЛАТЫ',
        key: 'voluntary_payment_status_place_owner'
      }
    ]
  }
];
